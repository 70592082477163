import { MissionMetrics } from './MissionMetric';

export interface GeographicMissionRequirement {
  id: string;
  name?: string;
}

const geographicMissionRequirementFromResponse = (
  responseData: any,
): GeographicMissionRequirement => ({
  id: responseData.id,
  name: responseData.name,
});

export interface MissionRequirements {
  metros: GeographicMissionRequirement[];
  locations: GeographicMissionRequirement[];
  country?: string;
  ordersMustContain: string[];
  metrics: MissionMetrics;
}

export const missionRequirementsFromResponse = (
  responseData: any,
): MissionRequirements => ({
  metros: (responseData.metros ?? []).map((metro: any) =>
    geographicMissionRequirementFromResponse(metro),
  ),
  locations: (responseData.locations ?? []).map((location: any) =>
    geographicMissionRequirementFromResponse(location),
  ),
  country: responseData.country_code,
  ordersMustContain: responseData.orders_must_contain ?? [],
  metrics: responseData.metrics.reduce((acc: any, metric: any) => {
    acc[metric.type] = { amount: metric.required, operator: '>=' };
    return acc;
  }, {} as MissionMetrics),
});
