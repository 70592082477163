import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useState } from 'react';
import { isEmpty, isNumber } from 'lodash';
import { useRecoilValue } from 'recoil';
import { toast } from 'material-react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../atoms/Location';
import { MissionSegment } from '../../interfaces/Mission/MissionSegment';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import StringField from '../StringField';
import {
  getShortenedLocationFieldValue,
  getShortenedRecentlyActiveAtFieldValue,
} from '../../utilities/MissionSegment';
import { multiClass } from '../../utilities/Extensions';
import { locationIdToName, metroIDToName } from '../../utilities/Locations';
import { getMissionParticipants } from '../../services/missions';
import Waiting from '../Waiting';
import {
  easternTimezone,
  formatCsvDateTime,
  formatLocalTimeV2,
  formatMomentAsFilePostfix,
} from '../../utilities/Dates';

interface MissionSegmentTableProps {
  missionID?: number;
  currency: string;
  segments: MissionSegment[];
}

const MissionSegmentTable = (props: MissionSegmentTableProps) => {
  const { classes } = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const locations = useRecoilValue(locationsAtom);
  const metros = useRecoilValue(metrosAtom);

  const [loading, setLoading] = useState(false);

  const handleParticipantsExport = () => {
    if (!isNumber(props.missionID)) {
      return;
    }

    setLoading(true);
    getMissionParticipants(props.missionID)
      .then((participants) => {
        const csvRows = [
          [
            'DP ID',
            'Segment Name',
            'Invited At (ET)',
            'Accepted At (ET)',
            'Completed At (ET)',
            'Orders Completed',
            'Trips Completed',
            'Expected Earnings',
            'Actual Earnings',
            'Acceptance Rate',
          ],
          ...participants.map((participant) =>
            [
              participant.driverID,
              participant.segmentName,
              formatCsvDateTime(participant.invitedAt, easternTimezone),
              participant.acceptedAt
                ? formatCsvDateTime(participant.acceptedAt, easternTimezone)
                : '-',
              participant.completedAt
                ? formatCsvDateTime(participant.completedAt, easternTimezone)
                : '-',
              participant.orderCount.toString(),
              participant.tripCount.toString(),
              getSymbolFromCurrency(props.currency) +
                (participant.expectedEarnings / 100).toFixed(2),
              getSymbolFromCurrency(props.currency) +
                (participant.actualEarnings / 100).toFixed(2),
              participant.acceptanceRate
                ? participant.acceptanceRate.toString()
                : '-',
            ].map((column) => column.replaceAll(',', '_')),
          ),
        ].map((row) => row.join(','));

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute(
          'download',
          `participants_${formatMomentAsFilePostfix()}.csv`,
        );
        a.click();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleBrazeExport = (segment: MissionSegment) => {
    if (!isNumber(props.missionID)) {
      return;
    }

    setLoading(true);
    getMissionParticipants(props.missionID, segment.id)
      .then((participants) => {
        const csvRows = [
          'external_id',
          ...participants.map((participant) => participant.driverID),
        ];

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute(
          'download',
          `${segment.name.replaceAll(' ', '_')}_participants.csv`,
        );
        a.click();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const getStatPercentageText = (stat: number, invited: number): string =>
    invited === 0 || stat === 0
      ? ''
      : `(${Math.floor((stat / invited) * 100).toString()}%)`;

  const totalInvited = props.segments.reduce(
    (total, segment) => total + segment.stats.invited,
    0,
  );
  const totalAccepted = props.segments.reduce(
    (total, segment) => total + segment.stats.accepted,
    0,
  );
  const totalStarted = props.segments.reduce(
    (total, segment) => total + segment.stats.started,
    0,
  );
  const totalCompleted = props.segments.reduce(
    (total, segment) => total + segment.stats.completed,
    0,
  );
  const totalExpectedCosts = props.segments.reduce(
    (total, segment) => total + segment.stats.expectedCosts,
    0,
  );
  const totalActualCosts = props.segments.reduce(
    (total, segment) => total + segment.stats.actualCosts,
    0,
  );

  return (
    <Box component='div' className={classes.body}>
      <Waiting open={loading} />
      <Box component='div' className={classes.row}>
        <Typography
          className={multiClass([
            sharedClasses.h5,
            classes.title,
            classes.verticalCenter,
          ])}
        >
          Segments
        </Typography>
        <Box component='div' className={classes.horizontalSpacer} />
        <Button
          color='primary'
          variant='contained'
          startIcon={<DownloadIcon />}
          disabled={isEmpty(
            props.segments.filter((segment) => !segment.scheduledFor),
          )}
          onClick={handleParticipantsExport}
          className={multiClass([
            classes.verticalCenter,
            sharedClasses.buttonText,
            classes.exportParticipantsButton,
          ])}
        >
          Export Participants
        </Button>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell key='segment-name'>
                <Typography className={classes.columnLabel}>
                  Segment Name
                </Typography>
              </TableCell>
              <TableCell key='recently-active-at'>
                <Typography className={classes.columnLabel}>
                  Recently Active At
                </Typography>
              </TableCell>
              <TableCell key='must-be-active-at'>
                <Typography className={classes.columnLabel}>
                  Must Be Active At
                </Typography>
              </TableCell>
              <TableCell key='must-be-inactive'>
                <Typography className={classes.columnLabel}>
                  DP Must Be Inactive
                </Typography>
              </TableCell>
              <TableCell key='push-notifications'>
                <Typography className={classes.columnLabel}>
                  Push Notifications
                </Typography>
              </TableCell>
              <TableCell key='invited'>
                <Typography className={classes.columnLabel}>Invited</Typography>
              </TableCell>
              <TableCell key='accepted'>
                <Typography className={classes.columnLabel}>
                  Accepted
                </Typography>
              </TableCell>
              <TableCell key='started'>
                <Typography className={classes.columnLabel}>Started</Typography>
              </TableCell>
              <TableCell key='completed'>
                <Typography className={classes.columnLabel}>
                  Completed
                </Typography>
              </TableCell>
              <TableCell key='expected-costs'>
                <Typography className={classes.columnLabel}>
                  Expected Costs
                </Typography>
              </TableCell>
              <TableCell key='actual-costs'>
                <Typography className={classes.columnLabel}>
                  Actual Costs
                </Typography>
              </TableCell>
              <TableCell key='last-updated-by'>
                <Typography className={classes.columnLabel}>
                  Last Updated By
                </Typography>
              </TableCell>
              <TableCell key='export' align='center'>
                <Typography className={classes.columnLabel}>Export</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty(props.segments) ? (
              <TableRow key='no-segments'>
                <TableCell colSpan={13} align='center'>
                  <Typography
                    className={multiClass([
                      sharedClasses.subtitle2,
                      classes.noSegmentText,
                    ])}
                  >
                    No segments
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              [
                props.segments.map((segment) => {
                  const isItalic = !!segment.scheduledFor;

                  const locationNames =
                    segment.invitationRequirements.locationIDs.map(
                      (locationID) =>
                        locationIdToName(locations, parseInt(locationID, 10)),
                    );
                  const metroNames =
                    segment.invitationRequirements.metroIDs.map((metroID) =>
                      metroIDToName(metros, metroID),
                    );
                  const recentlyActiveAtNames = [
                    ...locationNames,
                    ...metroNames,
                  ];

                  return (
                    <TableRow key={segment.id}>
                      <TableCell>
                        <StringField
                          value={
                            segment.name +
                            (segment.scheduledFor
                              ? ` (Scheduled for ${formatLocalTimeV2(
                                  segment.scheduledFor,
                                )} UTC)`
                              : '')
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell
                        title={
                          isEmpty(recentlyActiveAtNames)
                            ? undefined
                            : recentlyActiveAtNames.join(', ')
                        }
                      >
                        <StringField
                          value={getShortenedRecentlyActiveAtFieldValue(
                            recentlyActiveAtNames,
                          )}
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell
                        title={
                          isEmpty(segment.invitationRequirements.mustBeActiveAt)
                            ? undefined
                            : segment.invitationRequirements.mustBeActiveAt
                                .map((locationID) =>
                                  locationIdToName(
                                    locations,
                                    parseInt(locationID, 10),
                                  ),
                                )
                                .join(', ')
                        }
                      >
                        <StringField
                          value={
                            isEmpty(
                              segment.invitationRequirements.mustBeActiveAt,
                            )
                              ? '-'
                              : getShortenedLocationFieldValue(
                                  segment.invitationRequirements.mustBeActiveAt,
                                  locations,
                                )
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={
                            segment.invitationRequirements.mustBeInactive
                              ? 'Yes'
                              : 'No'
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={
                            segment.pushNotificationsSent
                              ? 'Enabled'
                              : 'Disabled'
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={segment.stats.invited.toString()}
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={`${segment.stats.accepted.toString()} ${getStatPercentageText(
                            segment.stats.accepted,
                            segment.stats.invited,
                          )}`}
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={`${segment.stats.started.toString()} ${getStatPercentageText(
                            segment.stats.started,
                            segment.stats.invited,
                          )}`}
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={`${segment.stats.completed.toString()} ${getStatPercentageText(
                            segment.stats.completed,
                            segment.stats.invited,
                          )}`}
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={
                            getSymbolFromCurrency(props.currency) +
                            (segment.stats.expectedCosts / 100).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={
                            getSymbolFromCurrency(props.currency) +
                            (segment.stats.actualCosts / 100).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell>
                        <StringField
                          value={
                            segment.audit.lastUpdatedBy ??
                            segment.audit.createdBy
                          }
                          italic={isItalic}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          color='primary'
                          disabled={!!segment.scheduledFor}
                          onClick={() => handleBrazeExport(segment)}
                          className={sharedClasses.buttonText}
                        >
                          Braze Export
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }),
                <TableRow key='total-row'>
                  <TableCell colSpan={5}>
                    <Typography className={classes.totalStringField}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.totalStringField}>
                      {totalInvited.toString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.totalStringField}>
                      {`${totalAccepted.toString()} ${getStatPercentageText(
                        totalAccepted,
                        totalInvited,
                      )}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.totalStringField}>
                      {`${totalStarted.toString()} ${getStatPercentageText(
                        totalStarted,
                        totalInvited,
                      )}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.totalStringField}>
                      {`${totalCompleted.toString()} ${getStatPercentageText(
                        totalCompleted,
                        totalInvited,
                      )}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.totalStringField}>
                      {getSymbolFromCurrency(props.currency) +
                        (totalExpectedCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.totalStringField}>
                      {getSymbolFromCurrency(props.currency) +
                        (totalActualCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Typography>
                  </TableCell>
                </TableRow>,
              ]
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  body: {
    marginTop: theme.spacing(6),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    marginBottom: theme.spacing(1.5),
  },
  horizontalSpacer: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: sharedColors.gray1,
  },
  tableHeader: {
    backgroundColor: sharedColors.gray2,
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  noSegmentText: {
    flexGrow: 1,
    textAlign: 'center',
    lineHeight: 2,
  },
  totalStringField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '32px',
    flexDirection: 'row',
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  exportParticipantsButton: {
    textTransform: 'none',
  },
}));

export default MissionSegmentTable;
