import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { createRef, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { toast } from 'material-react-toastify';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  locations as locationsAtom,
  metros as metrosAtom,
} from '../../../atoms/Location';
import { missionPageUrl } from '../../../variables/Pages';
import { multiClass } from '../../../utilities/Extensions';
import { sharedColors, useSharedStyles } from '../../../utilities/Styles';
import { getLocations, getMetros } from '../../../services/locations';
import Waiting from '../../Waiting';
import {
  getMissionTemplate,
  requirementsIncludeCountry,
} from '../../../utilities/Mission';
import {
  isCompletionCriteriaConfigurable,
  missionTypes,
} from '../../../variables/Mission';
import { createMission } from '../../../services/missions';
import { ConfirmDialog } from '../../NewPayRateDialog/ConfirmDialog';
import { requiredErrorMessage } from '../../../utilities/Constants';
import MultiLocationAutocomplete from '../../MultiLocationAutocomplete';
import MultiMetroAutocomplete from '../../MultiMetroAutocomplete';
import MaxDriverCountField from './InputForms/MaxDriverCountField';
import MissionAppPreview from './MissionAppPreview';
import {
  MissionKind,
  MissionOrderItem,
} from '../../../interfaces/Mission/Mission';
import { MissionMetrics } from '../../../interfaces/Mission/MissionMetric';
import CompletionCriteriaForm from './InputForms/CompletionCriteriaForm';
import { MissionRequirements } from '../../../interfaces/Mission/MissionRequirements';

const NewMissionPage = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const navigate = useNavigate();

  const validatorRef = createRef<ValidatorForm>();

  const [locations, setLocations] = useRecoilState(locationsAtom);
  const [metros, setMetros] = useRecoilState(metrosAtom);

  const [currentState, setCurrentState] = useState(getMissionTemplate());
  const [invalidForm, setInvalidForm] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [creating, setCreating] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingMetros, setLoadingMetros] = useState(false);

  const currentMissionType = missionTypes.find(
    (type) => type.kind === currentState.type,
  );

  useEffect(() => {
    setLoadingLocations(true);
    getLocations()
      .then((fetchedLocations) => setLocations(fetchedLocations))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingLocations(false));

    setLoadingMetros(true);
    getMetros()
      .then((fetchedMetros) => setMetros(fetchedMetros))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingMetros(false));
  }, []);

  useEffect(() => {
    validatorRef.current?.isFormValid(false).then((isValid) => {
      setInvalidForm(!isValid);
    });
  }, [currentState]);

  useEffect(() => {
    if (currentMissionType) {
      setCurrentState(currentMissionType.templateGenerator(currentState));
    }
  }, [currentState.type]);

  const handleGoBack = () => {
    if (navigate) {
      navigate(missionPageUrl);
    }
  };

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, title: e.target?.value ?? '' });
  };

  const handleChangeDescription = (e: any) => {
    setCurrentState({ ...currentState, description: e.target?.value });
  };

  const handleSetStartAt = (newStartAt: Date | null) =>
    setCurrentState({
      ...currentState,
      startAtLocal: newStartAt ?? new Date(),
    });

  const handleSetEndAt = (newEndAt: Date | null) =>
    setCurrentState({
      ...currentState,
      endAtLocal: newEndAt ?? new Date(),
    });

  const handleToggleIndefiniteEndDate = () => {
    setCurrentState({
      ...currentState,
      endAtLocal: currentState.endAtLocal
        ? undefined
        : DateTime.fromJSDate(currentState.startAtLocal)
            .plus({ hour: 1 })
            .toJSDate(),
    });
  };

  const adjustMissionTypeByRequirements = (
    newRequirements: MissionRequirements,
  ): MissionKind => {
    if (currentState.type !== MissionKind.PER_ORDER_BOOST) {
      return currentState.type;
    }

    if (requirementsIncludeCountry(locations, metros, newRequirements, 'US')) {
      toast.warn('Per Order Bonuses are not available in US locations');
      return MissionKind.PER_TRIP_BOOST;
    }

    return currentState.type;
  };

  const handleChangeMetrics = (newMetrics: MissionMetrics) => {
    setCurrentState({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        metrics: newMetrics,
      },
    });
  };

  const handleChangeLocations = (newLocationIDs: string[]) => {
    const newRequirements: MissionRequirements = {
      ...currentState.requirements,
      country: undefined,
      metros: currentState.requirements.metros,
      locations: newLocationIDs.map((locationID) => ({ id: locationID })),
    };

    setCurrentState({
      ...currentState,
      type: adjustMissionTypeByRequirements(newRequirements),
      requirements: newRequirements,
    });
  };

  const handleChangeMetros = (newMetroIDs: string[]) => {
    const newRequirements: MissionRequirements = {
      ...currentState.requirements,
      country: undefined,
      locations: currentState.requirements.locations,
      metros: newMetroIDs.map((metroID) => ({ id: metroID })),
    };

    setCurrentState({
      ...currentState,
      type: adjustMissionTypeByRequirements(newRequirements),
      requirements: newRequirements,
    });
  };

  const handleToggleCountry = (country: string) => {
    setCurrentState({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        country:
          currentState.requirements.country === country ? undefined : country,
      },
    });
  };

  const handleChangeMissionType = (e: any) => {
    setCurrentState({
      ...currentState,
      type: e.target.value ?? missionTypes[0].kind,
    });
  };

  const handleChangeMaxDriverCount = (newMaxDriverCount?: number) =>
    setCurrentState({ ...currentState, maxDriverCount: newMaxDriverCount });

  const handleToggleAlcoholOrdersOnly = () => {
    setCurrentState({
      ...currentState,
      requirements: {
        ...currentState.requirements,
        ordersMustContain: currentState.requirements.ordersMustContain.includes(
          MissionOrderItem.ALCOHOL,
        )
          ? currentState.requirements.ordersMustContain.filter(
              (item) => item !== MissionOrderItem.ALCOHOL,
            )
          : [
              ...currentState.requirements.ordersMustContain,
              MissionOrderItem.ALCOHOL,
            ],
      },
    });
  };

  const handleOnCreate = (createdMissionID: number) => {
    toast.success('Successfully created new mission');
    if (navigate) {
      navigate(`${missionPageUrl}/details/${createdMissionID.toString()}`);
    }
  };

  const handleSubmit = () => {
    setShowConfirmDialog(false);
    if (!currentMissionType) {
      return;
    }

    setCreating(true);
    createMission(currentState, currentMissionType)
      .then(handleOnCreate)
      .catch((err) => toast.error(err.message))
      .finally(() => setCreating(false));
  };

  const requirementsFieldValid =
    !!currentState.requirements.country ||
    !isEmpty(currentState.requirements.locations) ||
    !isEmpty(currentState.requirements.metros);

  let applicableMissionTypes = missionTypes;
  if (
    requirementsIncludeCountry(
      locations,
      metros,
      currentState.requirements,
      'US',
    )
  ) {
    applicableMissionTypes = applicableMissionTypes.filter(
      (missionType) => missionType.kind !== MissionKind.PER_ORDER_BOOST,
    );
  }

  const loading = creating || loadingLocations || loadingMetros;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <ConfirmDialog
        title='Are you sure?'
        content='Confirm adding new mission'
        okText='Confirm'
        cancelText='Cancel'
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleSubmit}
        color='primary'
      />
      <Paper className={classes.header}>
        <Box component='div' className={classes.titleContainer}>
          <IconButton
            onClick={handleGoBack}
            className={sharedClasses.iconColor}
          >
            <ChevronLeftIcon fontSize='large' />
          </IconButton>
          <Typography
            className={multiClass([classes.verticalCenter, sharedClasses.h2])}
          >
            Create New Mission
          </Typography>
        </Box>
      </Paper>
      <Box component='div' className={classes.body}>
        <MissionAppPreview currentState={currentState} />
        <Box component='div' className={classes.form}>
          <ValidatorForm
            instantValidate
            onSubmit={() => setShowConfirmDialog(true)}
            onError={() => setInvalidForm(true)}
            ref={validatorRef}
          >
            <Box component='div' className={classes.dialogContent}>
              <Typography
                className={multiClass([
                  classes.sectionLabel,
                  sharedClasses.subtitle1,
                ])}
              >
                Set Name & Description
              </Typography>
              <Link
                rel='noopener noreferrer'
                href='https://docs.google.com/document/d/1hZKePPi0rOa22xMImkIE5VPEQE1YWVB92LPKoBkbH5A/edit'
                target='_blank'
                className={classes.templateLink}
              >
                Link to templates ↗
              </Link>
              <TextValidator
                label='Mission name'
                variant='outlined'
                fullWidth
                name='name'
                value={currentState.title}
                onChange={handleChangeName}
                validators={['required']}
                errorMessages={[requiredErrorMessage]}
                className={classes.nameDescriptionTextField}
              />
              <TextField
                label='Mission description'
                variant='outlined'
                fullWidth
                multiline
                value={currentState.description ?? ''}
                onChange={handleChangeDescription}
                className={classes.nameDescriptionTextField}
              />
            </Box>
            <Divider />
            <Box component='div' className={classes.dialogContent}>
              <Typography
                className={multiClass([
                  classes.sectionLabel,
                  sharedClasses.subtitle1,
                ])}
              >
                Set Incentive Type
              </Typography>
              <FormControl component='fieldset'>
                <RadioGroup
                  value={currentState.type}
                  onChange={handleChangeMissionType}
                >
                  {applicableMissionTypes.map((missionType) => [
                    <FormControlLabel
                      key={missionType.kind}
                      value={missionType.kind}
                      control={<Radio id={missionType.kind} color='primary' />}
                      label={
                        <Typography className={sharedClasses.body1}>
                          {missionType.name}
                        </Typography>
                      }
                    />,
                    currentState.type === missionType.kind &&
                      missionType.inputGenerator(
                        currentState,
                        setCurrentState,
                        true,
                      ),
                  ])}
                </RadioGroup>
              </FormControl>
            </Box>
            <Divider />
            <Box component='div' className={classes.dialogContent}>
              <Typography
                className={multiClass([
                  classes.sectionLabel,
                  sharedClasses.subtitle1,
                ])}
              >
                Set Time Range (MFC local time)
              </Typography>
              <Box component='div' className={classes.wrappedRow}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    onChange={handleSetStartAt}
                    value={currentState.startAtLocal}
                    minDate={new Date()}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps,
                    ) => (
                      <TextField {...params} size='small' label='Start At' />
                    )}
                    className={classes.startAtDatePicker}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    onChange={handleSetEndAt}
                    value={currentState.endAtLocal ?? currentState.startAtLocal}
                    minDate={currentState.startAtLocal}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps,
                    ) => <TextField {...params} size='small' label='End At' />}
                    disabled={!currentState.endAtLocal}
                    className={classes.endAtDatePicker}
                  />
                </LocalizationProvider>
                {currentMissionType?.allowIndefiniteEndAt && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!currentState.endAtLocal}
                        onChange={handleToggleIndefiniteEndDate}
                      />
                    }
                    label={
                      <Typography className={sharedClasses.subtitle2}>
                        Indefinite
                      </Typography>
                    }
                  />
                )}
              </Box>
            </Box>
            <Divider />
            <Box component='div' className={classes.dialogContent}>
              <Grid container>
                <Grid item>
                  <Typography
                    className={multiClass([
                      classes.sectionLabel,
                      sharedClasses.subtitle1,
                    ])}
                  >
                    Set Completion Criteria
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      'Completion criteria is only configurable for single tiered order missions.'
                    }
                  >
                    <InfoOutlinedIcon
                      fontSize='small'
                      className={classes.tooltipIcon}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <CompletionCriteriaForm
                metrics={currentState.requirements.metrics}
                onChange={handleChangeMetrics}
                disabled={!isCompletionCriteriaConfigurable(currentState)}
              />
            </Box>
            <Divider />
            <Box component='div' className={classes.dialogContent}>
              <Typography
                className={multiClass([
                  classes.sectionLabel,
                  sharedClasses.subtitle1,
                ])}
              >
                {`Set Delivery Eligibility${
                  requirementsFieldValid ? '' : ' *'
                }`}
              </Typography>
              <Box component='div' className={classes.wrappedRow}>
                <MultiLocationAutocomplete
                  label='Locations'
                  locations={locations}
                  selectedIDs={currentState.requirements.locations.map(
                    (location) => location.id,
                  )}
                  onSetSelectedIDs={handleChangeLocations}
                  disabled={!!currentState.requirements.country}
                  error={!requirementsFieldValid}
                />
                <Box component='div' className={classes.metroAutocomplete}>
                  <MultiMetroAutocomplete
                    label='Metros'
                    metros={metros}
                    selectedIDs={currentState.requirements.metros.map(
                      (metro) => metro.id,
                    )}
                    onSetSelectedIDs={handleChangeMetros}
                    disabled={!!currentState.requirements.country}
                    error={!requirementsFieldValid}
                  />
                </Box>
                <Box component='div' className={classes.countryRow}>
                  <FormControlLabel
                    disabled={
                      !isEmpty(currentState.requirements.locations) ||
                      !isEmpty(currentState.requirements.metros)
                    }
                    control={
                      <Checkbox
                        checked={currentState.requirements.country === 'GB'}
                        onChange={() => handleToggleCountry('GB')}
                      />
                    }
                    label={
                      <Typography className={sharedClasses.subtitle2}>
                        All UK
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentState.requirements.ordersMustContain.includes(
                      MissionOrderItem.ALCOHOL,
                    )}
                    onChange={handleToggleAlcoholOrdersOnly}
                  />
                }
                label={
                  <Typography className={sharedClasses.subtitle2}>
                    Applicable to alcohol deliveries only
                  </Typography>
                }
                className={classes.alcoholCheckbox}
              />
            </Box>
            <Divider />
            <Box component='div' className={classes.dialogContent}>
              <Typography
                className={multiClass([
                  classes.sectionLabel,
                  sharedClasses.subtitle1,
                ])}
              >
                Set Maximum DP Count
              </Typography>
              <MaxDriverCountField
                value={currentState.maxDriverCount}
                onChange={handleChangeMaxDriverCount}
              />
            </Box>
            <Box component='div' className={classes.actionsRow}>
              <Box component='div' className={classes.horizontalSpacer} />
              <Button
                variant='contained'
                color='primary'
                disabled={invalidForm || !requirementsFieldValid}
                type='submit'
                className={multiClass([
                  sharedClasses.buttonText,
                  classes.actionButton,
                ])}
              >
                Save
              </Button>
            </Box>
          </ValidatorForm>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    header: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(3.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(6),
    },
    tooltipIcon: {
      color: sharedColors.blue4,
      marginLeft: 2,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      flexWrap: 'wrap',
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(2.5),
    },
    appPaper: {
      marginTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
      height: 'fit-content',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(2.5),
    },
    verticalCenter: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingRight: theme.spacing(3.5),
    },
    sectionLabel: {
      marginBottom: theme.spacing(2.5),
    },
    templateLink: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(1),
    },
    nameDescriptionTextField: {
      width: 428,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1.5),
    },
    startAtDatePicker: {
      marginRight: theme.spacing(1.5),
    },
    endAtDatePicker: {
      marginRight: theme.spacing(1.5),
    },
    metroAutocomplete: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    countryRow: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      flexWrap: 'wrap',
    },
    alcoholCheckbox: {
      marginTop: theme.spacing(1),
    },
    currencyDropdown: {
      width: 350,
    },
    currencyLabel: {
      backgroundColor: 'white',
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    actionsRow: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    actionButton: {
      marginLeft: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    horizontalSpacer: {
      flexGrow: 1,
    },
    wrappedRow: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });
});

export default NewMissionPage;
